.adminAllProductsFilterBox {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  /* align-items: center; */
}
.adminAllProductsFilterLabelBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.adminAllProductsFilterLabelBox input:focus {
  box-shadow: 0px 0px 3px var(--primary);
}
.adminAllProductsFilterLabelBox input {
  width: 50%;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px solid #ced4da;
  box-shadow: 0px 0px 1px rgba(2, 168, 181, 0.2);
  outline: none;
  box-sizing: border-box;
}
.adminAllProductsFilterLabelBox input:first-child {
  margin-right: 20px;
}
.adminAllProductsFilterCategoryBox {
  display: flex;
  flex-direction: column;
  /* width: 97.5%; */
  /* flex-wrap: wrap; */
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
}
.adminAllProductsFilterCategoryBox select {
  width: 100%;
  height: 30px;
  border-radius: 5px;
  margin: 5px 0;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px solid #ced4da;
  box-shadow: 0px 0px 1px rgba(2, 168, 181, 0.2);
  outline: none;
  box-sizing: border-box;
}
.adminAllProductsFilterCategoryBox select:focus {
  box-shadow: 0px 0px 3px var(--primary);
}
.adminAllProductsFilterDatesBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 30px; */
  margin-top: 5px;
}
.adminAllProductsFilterDatesBox input {
  width: 45%;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px solid #ced4da;
  box-shadow: 0px 0px 1px rgba(2, 168, 181, 0.2);
  outline: none;
  box-sizing: border-box;
}
.adminInventoryMainTable {
  font-size: 12px;
  text-align: start;
}
.adminInventoryMainTable th {
  text-align: start;
  padding: 10px 0;
  padding-right: 10px;
  white-space: nowrap;
  /* border: 2px solid red; */
}
.adminInventoryMainTable td {
  padding: 10px 0;
  padding-right: 10px;
  /* white-space: nowrap; */
}
.adminInventoryMobilePrintButtonsBox {
  display: none;
}
.adminAddBulkStockShowEditBox {
  display: flex;
  justify-content: space-between;
  padding: 10px 25px;
}
.adminAddBulkStockShowEditButton {
  cursor: pointer;
}
.adminAddBulkStockShowEditButton:hover {
  color: var(--primary);
}
.adminAddBulkStockAddedTitle {
  color: var(--primary);
}
.adminAddBulkStockAddedProductsOuterBox {
  background-color: white;
  border-radius: 5px;
  padding: 10px 0px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  box-sizing: border-box;
  margin-bottom: 5rem;
  width: 98%;
  margin-left: 1%;
  margin-top: 10px;
}
.bulkStockAddProductAddImagesIcon:hover {
  color: var(--btnHoverPrimary);
}
/* popupbtn */
.updateAccountDetailsMainBox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
}
.updateAccountDetailsMainBox button {
  background: linear-gradient(
    90deg,
    rgba(41, 0, 0, 0.8491771708683473) 0%,
    rgba(121, 48, 9, 0.7959558823529411) 35%,
    rgba(255, 145, 0, 0.8519782913165266) 100%
  );
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.5rem;
  font-weight: bolder;
  border-color: white;
}
.updateAccountDetailsMainBox button:hover {
  border-color: black;
  cursor: pointer;
  color: white;
}
.updateAccountDetailsPopupMainBox {
  position: absolute;
  width: 40vw;
  /* aspect-ratio: 1; */
  height: 60vh;
  border: 5px solid peru;
  background-color: white;
  z-index: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.updateAccountDetailsPopupCloseBtn {
  margin-top: 5rem;
  width: 2rem;
  cursor: pointer;
}
.updateAccountDetailsPopupCloseBtn:hover {
  color: peru;
}
.updateAccountDetailsPopupMainBox input {
  width: 15rem;
  margin: 2rem 0;
  font-size: larger;
  padding: 0.5rem;
}
.updateAccountDetailsPopupMainBox button {
  width: 17rem;
  padding: 0.5rem;
  background: linear-gradient(
    90deg,
    rgba(41, 0, 0, 0.8491771708683473) 0%,
    rgba(121, 48, 9, 0.7959558823529411) 35%,
    rgba(255, 145, 0, 0.8519782913165266) 100%
  );
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.5rem;
  font-weight: bolder;
  border-color: white;
}
.updateAccountDetailsPopupMainBox button:hover {
  border-color: black;
  cursor: pointer;
  color: white;
}
.adminProductDetailsMainAddImageBox {
  display: flex;
  align-items: center;
}
.adminProductDetailsMainAddImageBox h3 {
  color: var(--primary);
  cursor: pointer;
  font-size: 16px;
  transition: all ease-in-out 0.5s;
}
.adminProductDetailsMainAddImageBox h3:hover,
.adminProductDetailsMainAddImageBox h3:focus,
.adminProductDetailsMainAddImageIcon:hover,
.adminProductDetailsMainAddImageIcon:focus {
  text-decoration: underline;
  color: var(--btnHoverPrimary);
}

/* Unlabelled Stock */
.adminUnlabelledStockMainOuterBox {
  /* max-height: 50vh;
  overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.adminUnlabelledStockMainItemsBox {
  width: 100%;
  max-height: 50vh;
  overflow-y: scroll;
  margin-bottom: 40px;
  box-sizing: border-box;
}
.adminUnlabelledStockMainAddLabelBox {
  width: 92vw;
  box-sizing: border-box;
}
.adminCreditNoteOption {
  list-style: none;
  text-decoration: underline;
  cursor: pointer;
  /* color: rebeccapurple; */
  color: var(--primary);
}
.adminCreditNoteOption:hover,
.adminCreditNoteOption:active {
  font-weight: bold;
  color: var(--btnHoverPrimary);
}
.adminCreditNoteCustomerDetailsBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  column-gap: 20px;
  row-gap: 20px;
  justify-items: center;
}
.adminCreditNoteCustomerDetailsBox > * {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.adminCreditNoteCustomerDetailsBox label {
  font-size: 13px;
}
.adminCreditNoteCustomerDetailsBox input,
.adminCreditNoteCustomerDetailsBox select {
  width: 50%;
  height: 30px;
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px solid #ced4da;
  box-shadow: 0px 0px 1px rgba(2, 168, 181, 0.2);
  outline: none;
  box-sizing: border-box;
}
@media (max-width: 1000px) {
  .adminAllLabelledListButtonBox {
    display: none;
  }
  .adminInventoryMobilePrintButtonsBox {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 20px;
    padding-bottom: 0;
    margin: 10px;
    box-sizing: border-box;
  }
  .adminInventoryMobilePrintButtonsBox button {
    width: 80px;
    min-height: 30px;
    margin: 10px;
    border-radius: 3px;
    cursor: pointer;
    background-color: var(--primary);
    color: white;
    /* border: none; */
    border: 1px solid var(--primary);
  }
}
