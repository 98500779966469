.cashReportUpperBox {
  width: 100%;
  /* background-color: aqua; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cashReportUpperBox label {
  margin: 10px;
  margin-left: 0;
}
.cashReportUpperBox input {
  height: 25px;
  border-radius: 5px;
  font-size: 14px;
  padding: 2px 5px;
  border: 1px solid #ced4da;
  box-shadow: 0px 0px 1px rgba(2, 168, 181, 0.2);
  outline: none;
}
.cashReportUpperBox input:focus,
.cashReportUpperBox input:hover {
  /* cursor: pointer; */
  box-shadow: 0px 0px 3px var(--primary);
}
.cashReportLowerBox {
  width: 100%;
  margin-top: 30px;
  /* height: 500px; */
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  /* background-color: brown; */
}
.cashReportLowerItemBox {
  text-align: left;
  /* width: 500px; */
  /* height: 50px; */
  width: 45%;
  margin: 20px;
  box-sizing: border-box;
  /* border: 2px solid green; */
  /* background-color: blanchedalmond; */
}
.cashReportLowerItemBox table {
  width: 100%;
  border-collapse: collapse;
}
.cashReportLowerItemBox table tbody tr {
  width: 100%;
  border: 2px solid grey;
}
.cashReportLowerItemBox table tbody tr td {
  /* width: 100%; */
  border: 2px solid grey;
}
